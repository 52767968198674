import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import APInput from 'apex-web/lib/components/common/APInput';
import APButton from 'apex-web/lib/components/common/APButton';
import APIcon from 'apex-web/lib/components/common/APIcon';
import { required } from '../../helpers/formValidations';
import APPasswordInput from 'apex-web/lib/components/common/APPasswordInput/APPasswordInput';
import redirectIfAuthorized from 'apex-web/lib/hocs/redirectIfAuthorized';

import { defaultPath } from 'apex-web/lib/routeTemplates';
import path from '../../helpers/path';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import TradingServerComponent from 'apex-web/lib/components/TradingServer/TradingServerComponent';
import { styleNames } from '../../propTypes/commonComponent';
// import Big_X_Logo from "../../images/custom/Big_X_logo.svg";
import Big_X_Logo from '../../images/custom/Big_X_logo_new.png';
import '../../pages/css/Style.css';
import '../../pages/css/bootstrap.min.css';

// import '../../styles/components/common/StandaloneForm.css';
// import './LoginFormComponent.css';

var _slicedToArray = (function() {
  function sliceIterator(arr, i) {
    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = undefined;
    try {
      for (
        var _i = arr[Symbol.iterator](), _s;
        !(_n = (_s = _i.next()).done);
        _n = true
      ) {
        _arr.push(_s.value);
        if (i && _arr.length === i) break;
      }
    } catch (err) {
      _d = true;
      _e = err;
    } finally {
      try {
        if (!_n && _i['return']) _i['return']();
      } finally {
        if (_d) throw _e;
      }
    }
    return _arr;
  }
  return function(arr, i) {
    if (Array.isArray(arr)) {
      return arr;
    } else if (Symbol.iterator in Object(arr)) {
      return sliceIterator(arr, i);
    } else {
      throw new TypeError(
        'Invalid attempt to destructure non-iterable instance'
      );
    }
  };
})();

var baseClasses = getBEMClasses('standalone-form');
var loginFormClasses = getBEMClasses('login-form');

export var LoginFormComponent = function LoginFormComponent(props, context) {
  // console.log(props.redirectLocation)
  let redirect_page = props.redirectLocation.pathname;
  let redirect_page_param = props.redirectLocation.search;
  console.log('Next page : ' + redirect_page);
  console.log('Next page param : ' + redirect_page_param);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const pwnu_email = urlParams.get('pwnu_email');
  console.log(pwnu_email);

  // const [showNotice, setShowNotice] = useState(true);

  useEffect(() => {
    document.title = 'Green-X Login';

    // Set timer for system upgrade notice
    // const checkTime = () => {
    //   const currentTime = Math.floor(Date.now() / 1000);
    //   // Local Date and Time September 18, 2024 @ 3:00:00 am
    //   // UTC Date and Time September 17, 2024 @ 7:00:00 pm
    //   const endTime = 1726599600;
    //   setShowNotice(currentTime > endTime);
    // };
    // checkTime();
    // const intervalId = setInterval(checkTime, 1000);
    // return () => clearInterval(intervalId);
  }, []);

  var handleSubmit = props.handleSubmit,
    errorMsg = props.errorMsg,
    submitting = props.submitting,
    doSubmit = props.doSubmit,
    isConnected = props.isConnected,
    useEmailAsUsername = props.useEmailAsUsername,
    active = props.active,
    siteName = props.siteName,
    gateway = props.gateway,
    formGateway = props.formGateway,
    resetForm = props.resetForm;

  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    customGateway = _useState2[0],
    setCustomGateway = _useState2[1];

  if (formGateway === 'CUSTOM' && !customGateway) {
    setCustomGateway(true);
    resetForm('gateway');
  }

  return React.createElement(
    'div',
    {
      className: 'login__screen__main'
    },
    //   <Image>
    //   <object
    //     id="animation"
    //     type="image/svg+xml"
    //     data={Big_X_Logo}
    //     alt="logo"
    //     style={{ width: '200px', marginTop: '30px' }}
    //   />
    // </Image>,
    React.createElement(
      'div',
      { className: 'X_object_login' },
      React.createElement('img', {
        src: Big_X_Logo,
        alt: 'logo'
      })
    ),
    React.createElement(
      'div',
      {
        className: 'mobile__header'
      },
      React.createElement(
        'span',
        { className: '' }
        // context.t("Log In to {n}", {
        //   n: siteName,
        // })
      ),
      React.createElement('span', {
        className: ''
      })
    ),
    React.createElement(
      'header',
      {},
      React.createElement(
        'div',
        {
          className: 'login__screen__btns'
        },
        React.createElement(
          'a',
          {
            className: 'theme-btn'
            // href="#"
          },
          context.t('Log In')
        ),
        React.createElement(
          Link,
          {
            className: 'theme-btn withoutgradient me-0 with-border',
            to: path('/signup'),
            // className: "",
            disabled: true

            // href="#"
          },
          context.t('Sign Up')
        )
      )
    ),

    React.createElement(
      'section',
      {
        className: 'login__form__center'
      },
      React.createElement(
        'div',
        {
          className: 'login__screen__content'
        },
        React.createElement(
          'div',
          {
            className: 'container'
          },
          React.createElement(
            'div',
            {
              className: 'row align-items-center'
            },
            React.createElement(
              'div',
              {
                className: 'col-lg-6'
              },
              React.createElement(
                'h1',
                { className: 'text_on_X_object' },
                redirect_page == '/GreenX-Presale-Order' ||
                redirect_page == '/GreenX-Seed-Round-Order'
                  ? context.t('GX Token Order Form')
                  : context.t('Sign in to GreenX')
              ),
              React.createElement(
                'div',
                {
                  className: 'system-notice'
                },
                // upgrade notice (by ChatGPT)
                React.createElement(
                  'h3',
                  { style: { marginTop: '10px' } },
                  context.t('Important Notice : ')
                ),
                React.createElement(
                  'p',
                  { style: { lineHeight: '1.3' } },
                  context.t(
                    'We will be performing a system upgrade on the following dates. '
                  ),
                  context.t(
                    'Some customers may experience temporary service interruption. '
                  ),
                  context.t(
                    'We apologize for any inconvenience and appreciate your patience.'
                  )
                ),
                React.createElement(
                  'p',
                  { style: { marginBottom: '0px' } },
                  context.t('US Time:')
                ),
                React.createElement(
                  'p',
                  { style: { fontFamily: 'Now-Bold' } },
                  'Sep 17th, 2:00 pm - 5:00 pm (UTC)'
                ),
                React.createElement(
                  'p',
                  { style: { marginBottom: '0px' } },
                  context.t('Hong Kong Time:')
                ),
                React.createElement(
                  'p',
                  { style: { fontFamily: 'Now-Bold' } },
                  'Sep 17th, 10:00 pm -  Sep 18th, 01:00 am (HKT)'
                )
                // end notice
              )
            ),
            React.createElement(
              'div',
              {
                className: 'col-lg-6'
              },
              // upgrade notice (by ChatGPT)
              // React.createElement(
              //   'div',
              //   { style: {marginBottom:'-50px'} },
              //   React.createElement(
              //     'p',
              //     { style: { color: 'red' } },
              //     context.t('Important Notice : ')
              //   ),
              //   React.createElement(
              //     'p',
              //     { style: { color: 'red', lineHeight: '1.3' } },
              //     context.t('We are upgrading our system on 14th May at evening.')
              //   ),
              //   React.createElement(
              //     'p',
              //     { style: { color: 'red' } },
              //     context.t('Some customers may experience temporary service interruption.')
              //   ),
              //   React.createElement(
              //     'p',
              //     { style: { color: 'red' } },
              //     context.t('We apologize for any inconvenience and appreciate your patience.')
              //   ),
              // ),
              // end notice
              React.createElement(
                'div',
                { className: 'cant__login' },
                active &&
                  React.createElement(
                    'ul',
                    { className: '' },
                    React.createElement(
                      'li',
                      { className: '' },
                      React.createElement(
                        'a',
                        {
                          href: path('https://www.green-x.io/'),
                          target: '_blank',
                          className: 'cant__login mb-4'
                          // disabled: true
                        },
                        context.t('Visit Our Website')
                      )
                    )
                  )
              ),
              React.createElement(
                'div',
                {
                  className: 'login__form__main'
                },
                React.createElement(
                  'form',
                  {
                    onSubmit: handleSubmit(function(values) {
                      return doSubmit(values, isConnected);
                    }),
                    className: ''
                  },
                  errorMsg &&
                    React.createElement(
                      'p',
                      { className: 'ap-input__error' },
                      errorMsg
                    ),
                  React.createElement(APInput, {
                    type: 'text',
                    name: 'username',
                    customClass: '',
                    placeholder: 'Username',
                    // className:'form-group',
                    label: useEmailAsUsername
                      ? context.t('Email')
                      : context.t('Username'),
                    // validate: [required]
                    is_prefill: pwnu_email == null ? false : true,
                    pre_fill_value: pwnu_email,
                    autoFocus: 'true'
                  }),

                  React.createElement(APPasswordInput, {
                    type: 'password',
                    name: 'password',
                    customClass: '',
                    placeholder: 'Password',

                    label: context.t('Password'),
                    validate: [required]
                  }),
                  (!gateway || customGateway) &&
                    React.createElement(APInput, {
                      type: 'text',
                      name: 'gateway',
                      customClass: '',
                      label: context.t('Gateway'),
                      validate: [required]
                    }),
                  !customGateway &&
                    Array.isArray(gateway) &&
                    React.createElement(TradingServerComponent, null),
                  // React.createElement("hr", { className: "" }),
                  React.createElement(
                    APButton,
                    {
                      type: 'submit',
                      disabled: submitting,
                      className: 'theme-btn',
                      customClass: '',
                      styleName: styleNames.additive
                    },
                    submitting
                      ? context.t('Processing...')
                      : context.t('Log In')
                  )
                ),
                React.createElement(
                  'div',
                  { className: 'cant__login' },
                  active &&
                    React.createElement(
                      'ul',
                      { className: '' },
                      React.createElement(
                        'li',
                        { className: '' },
                        React.createElement(
                          Link,
                          {
                            to: path('/signup'),
                            className: '',
                            disabled: true
                          },
                          context.t('Sign Up')
                        )
                      ),
                      React.createElement(
                        'li',
                        { className: '' },
                        React.createElement(
                          Link,
                          {
                            to: path('/problem-logging-in'),
                            className: '',
                            disabled: true
                          },
                          context.t("Can't Login?")
                        )
                      )
                    )
                )
                // React.createElement(
                //   'div',
                //   { className: 'cant__login cant_login_sto' },
                //   active &&
                //     React.createElement(
                //       'a',
                //       {
                //         href:
                //           'https://investorportal.cryptosx.io/settings/verification',
                //         target: '_blank'
                //         // className: "cant__login",
                //         // style: 'color:white',
                //         // disabled: true
                //       },
                //       context.t('For STO Investors: Start Accreditation')
                //     )
                // )
              )
            )
          )
        )
      )
    )
  );
};

LoginFormComponent.defaultProps = {
  handleSubmit: function handleSubmit() {},
  submitting: false
};

LoginFormComponent.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  referrer: PropTypes.string
};

LoginFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default redirectIfAuthorized(LoginFormComponent, defaultPath.path);
